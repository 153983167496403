import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";

import TrackOrder from "../TrackOrder/TrackOrder";
import DevTrackOrder from "../TrackOrder/DevTrackOrder";
import OrderInvoice from "../TrackOrder/OrderInvoice";
import DevOrderInvoice from "../TrackOrder/DevOrderInvoice";
import OrderTrackImage from "../TrackOrder/OrderTrackImage";
import DevOrderTrackImage from "../TrackOrder/DevOrderTrackImage";
import DevOrderTrackImageV2 from "../TrackOrder/DevOrderTrackImageV2";

const router = createBrowserRouter(
  createRoutesFromElements(
    // http://localhost:3000/?track-id=100824144146FaJNHD2x
    <Route>
      <Route path="/track-order/:trackId" element={<TrackOrder />} />
      <Route path="/oti/:orderId" element={<OrderTrackImage />} />
      <Route path="/di/:orderId" element={<OrderInvoice />} />
      {/* dev **/}
      <Route path="/dev/track-order/:trackId" element={<DevTrackOrder />} />
      {/*<Route path="/dev/oti/V2/:trackId" element={<DevOrderTrackImageV2 />} /> **/}
      <Route path="/dev/di/:orderId" element={<DevOrderInvoice />} />
      <Route path="/dev/oti/:orderId" element={<DevOrderTrackImage />} />
    </Route>
  )
);

export default router;
