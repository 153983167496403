import React from "react";
import { useParams } from "react-router-dom";

const OrderInvoice = () => {

   const { orderId } = useParams();

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <img
          src={"https://api.bill.ind.in/bi/di/" + orderId}
          alt="invoice"
        />
      </div>
    </div>
  );
};

export default OrderInvoice;
