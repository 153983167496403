import React from "react";
import { useParams } from "react-router-dom";

const DevOrderInvoice = () => {

   const { orderId } = useParams();

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <img src={"https://api.bill.ind.in/bi/dev/di/" + orderId} alt="invoice" />
      </div>
    </div>
  );
};

export default DevOrderInvoice;
