import React from "react";
import { useParams } from "react-router-dom";

const DevOrderTrackImage = () => {
   const { orderId } = useParams();
  
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <img
            src={
              "https://api.bill.ind.in/bi/dev/oti/" +
              orderId
            }
            alt="GarmentImage"
          />
        </div>
      </div>
    );
};

export default DevOrderTrackImage;
